<template>
  <loading
    :active="isLoading"
    :is-full-page="fullPage"
    :z-index="1100"
  ></loading>
  <div class="card mb-7">
    <div class="card-body pt-6 pb-0">
      <div class="d-flex overflow-auto h-55px">
        <ul
          class="nav nav-stretch text-center nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap"
        >
          <li class="nav-item">
            <router-link
              to="/raas/raas-affiliazioni"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Affiliazioni
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-consigli"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Consigli
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-tesserati"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Tesserati
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-documenti"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Documenti
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-sport"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Sport
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-didattica"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Didattica
            </router-link>
          </li>
          <li class="nav-item">
            <router-link
              to="/raas/raas-formazione"
              class="nav-link text-active-primary me-1 fs-6"
              active-class="active"
            >
              Formazione
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <router-view></router-view>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

import Loading from "vue3-loading-overlay";
import "vue3-loading-overlay/dist/vue3-loading-overlay.css";

export default defineComponent({
  name: "raas",
  components: { Loading },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Raas", []);
    });

    return {};
  },
});
</script>
